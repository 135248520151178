import Cookies from "universal-cookie";

export const setCookie = (name, value, expiry = 1) => {
  const cookies = new Cookies();
  cookies.set(name, value, { path: "/" });
};

export const getCookie = (name) => {
  const cookies = new Cookies();
  return cookies.get(name);
};

export const removeCookie = (name) => {
  const cookies = new Cookies();
  cookies.remove(name, { path: "/" });
};

export const removeAllCookie = () => {
  const cookies = new Cookies();
  cookies.remove();
};
